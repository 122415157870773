import { createSlice } from '@reduxjs/toolkit';
import {
	postQuickCode,
	getQuickCode,
	getQuickCodeDetails,
	patchQuickCode
} from './quickcode.thunk';

const initialState = {
	loading: false
}

const slice = createSlice({
	name: 'quickcode',
	initialState,
	reducers: {
		resetAction: () => initialState
	},
	extraReducers: {
		[postQuickCode.pending]: (state, action) => {
			state.loading = true
		},
		[postQuickCode.fulfilled]: (state, action) => {
			state.loading = false
		},
		[postQuickCode.rejected]: (state, action) => {
			state.loading = false
		},
		[getQuickCode.pending]: (state, action) => {
			state.loading = true
		},
		[getQuickCode.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getQuickCode.rejected]: (state, action) => {
			state.loading = false
		},
		[getQuickCodeDetails.pending]: (state, action) => {
			state.loading = true
		},
		[getQuickCodeDetails.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getQuickCodeDetails.rejected]: (state, action) => {
			state.loading = false
		},
		[patchQuickCode.pending]: (state, action) => {
			state.loading = true
		},
		[patchQuickCode.fulfilled]: (state, action) => {
			state.loading = false
		},
		[patchQuickCode.rejected]: (state, action) => {
			state.loading = false
		}
	}
})

export { postQuickCode, getQuickCode, getQuickCodeDetails, patchQuickCode }
export default slice.reducer