import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Spinner } from '..';
import { Switch } from '../inputs';
import { getSTCmappingDetails, patchSTCmapping } from '../../store/wms_stc_mapping/wms_stc_mapping.thunk';
import { MasterSelect } from '../../components/select';

const UpdateSTCmapping = ({
	isOpen,
	toggle,
	id
}) => {

	const dispatch = useDispatch();

	const [state, setState] = React.useState({
		isMappingError: false,
		id: {
			principal: '',
			store_code: '',
			store_group: '',
			vendor_code: '',
			mapping_status: '',
			stc_code: ''
		}
	});

	const handleUpdate = () => {
		dispatch(patchSTCmapping({
			route: 'stcmapping',
			data: {

				principal: state.id.principal.value,
				store_code: state.id.store_code,
				store_group: state.id.store_group,
				vendor_code: state.id.vendor_code,
				mapping_status: state.id.mapping_status,
				stc_code: state.id.stc_code
			}
		}))

		setState({
			...state,
			isMappingError: false,
			id: {
				principal: '',
				store_code: '',
				store_group: '',
				vendor_code: '',
				mapping_status: '',
				stc_code: ''
			}
		})
		toggle();
	}

	const handleChange = (e) => {
		setState({
			...state,
			id:
			{
				...state.id,
				[e.target.name]: e.target.value
			}
		})
	}

	const handleSelectChange = (e, name) => {
		setState({
			...state,
			id:
			{
				...state.id,
				[name]: e
			}
		})
	}

	React.useEffect(() => {
		if (id !== null && id !== 'Unknown ID') {
			dispatch(getSTCmappingDetails({
				route: 'stcmapping/details',
				filters: { ...id }
			}))
				.unwrap()
				.then(result => {
					setState({
						...state,
						id: {
							...result.data,
							principal: {
								value: result.data.rc_principal?.rc_id,
								label: result.data.rc_principal?.rc_desc
							}
						},
					})
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<Dialog open={isOpen}>
			<DialogTitle>Update STC Store Mapping</DialogTitle>
			<DialogContent dividers>
				<div>
					<Spinner reducer='itm' />
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<MasterSelect
								isDisabled
								fullWidth
								placeholder='Principal (Customer)'
								label='Principal (Customer)'
								name='principal'
								route='quickcode'
								type='HLS Principal'
								value={state.id.principal || ''}
								handleChange={handleSelectChange}
							/>
						</Grid>

						<Grid item container>
							<TextField
								disabled
								fullWidth
								name='store_code'
								variant='outlined'
								label='Store Code'
								value={state.id.store_code}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								disabled
								required
								fullWidth
								name='store_group'
								variant='outlined'
								label='Store Group'
								value={state.id.store_group}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								disabled
								required
								fullWidth
								name='vendor_code'
								variant='outlined'
								label='Vendor Code'
								value={state.id.vendor_code}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField

								required
								fullWidth
								name='stc_code'
								error={state.isMappingError}
								variant='outlined'
								label='STC Code'
								value={state.id.stc_code}
								onChange={handleChange}
							/>
						</Grid>

						<Grid item md={2} xs={12}>
							<Switch isLabelVisible
								label='Status'
								name='mapping_status'
								checked={state.id.mapping_status}
								handleChange={(e) => setState({ ...state, id: { ...state.id, mapping_status: e.target.checked } })} />
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={toggle} color='secondary'>Cancel</Button>
				<Button variant='contained' onClick={handleUpdate}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

UpdateSTCmapping.defaultProps = {
	isOpen: false,
	toggle: false,
	id: 'Unknown ID'
}

export default UpdateSTCmapping;