import { createSlice } from '@reduxjs/toolkit';
import {
	getReportCodes,
	getReport,
	getPricelist,
	postUpload,
	getPricelistDetails,
	patchPricelist,
	getSOitemlist,
	getSOlist,
	getSOitemHeader,
	patchRevLeak,
	userTriggerSOCF,
	getSIlist,
	downloadTemp,
	directPrinting
} from './fms_salem.thunk';

const initialState = {
	loading: false
}

const slice = createSlice({
	name: 'fms_salem',
	initialState,
	reducers: {
		resetAction: () => initialState
	},
	extraReducers: {
		[getReport.pending]: (state, action) => {
			state.loading = true
		},
		[getReport.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getReport.rejected]: (state, action) => {
			state.loading = false
		},
		[getPricelist.pending]: (state, action) => {
			state.loading = true
		},
		[getPricelist.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getPricelist.rejected]: (state, action) => {
			state.loading = false
		},
		[postUpload.pending]: (state, action) => {
			state.loading = true
		},
		[postUpload.fulfilled]: (state, action) => {
			state.loading = false
		},
		[postUpload.rejected]: (state, action) => {
			state.loading = false
		},
		[getPricelistDetails.pending]: (state, action) => {
			state.loading = true
		},
		[getPricelistDetails.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getPricelistDetails.rejected]: (state, action) => {
			state.loading = false
		},
		[patchPricelist.pending]: (state, action) => {
			state.loading = true
		},
		[patchPricelist.fulfilled]: (state, action) => {
			state.loading = false
		},
		[patchPricelist.rejected]: (state, action) => {
			state.loading = false
		},
		[getSOitemlist.pending]: (state, action) => {
			state.loading = true
		},
		[getSOitemlist.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getSOitemlist.rejected]: (state, action) => {
			state.loading = false
		},
		[getSOlist.pending]: (state, action) => {
			state.loading = true
		},
		[getSOlist.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getSOlist.rejected]: (state, action) => {
			state.loading = false
		},
		[getSOitemHeader.pending]: (state, action) => {
			state.loading = true
		},
		[getSOitemHeader.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getSOitemHeader.rejected]: (state, action) => {
			state.loading = false
		},
		[patchRevLeak.pending]: (state, action) => {
			state.loading = true
		},
		[patchRevLeak.fulfilled]: (state, action) => {
			state.loading = false
		},
		[patchRevLeak.rejected]: (state, action) => {
			state.loading = false
		},
		[userTriggerSOCF.pending]: (state, action) => {
			state.loading = true
		},
		[userTriggerSOCF.fulfilled]: (state, action) => {
			state.loading = false
		},
		[userTriggerSOCF.rejected]: (state, action) => {
			state.loading = false
		},
		[getSIlist.pending]: (state, action) => {
			state.loading = true
		},
		[getSIlist.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getSIlist.rejected]: (state, action) => {
			state.loading = false
		},
		[downloadTemp.pending]: (state, action) => {
			state.loading = true
		},
		[downloadTemp.fulfilled]: (state, action) => {
			state.loading = false
		},
		[downloadTemp.rejected]: (state, action) => {
			state.loading = false
		},
		[directPrinting.pending]: (state, action) => {
			state.loading = true
		},
		[directPrinting.fulfilled]: (state, action) => {
			state.loading = false
		},
		[directPrinting.rejected]: (state, action) => {
			state.loading = false
		},
	}
})

export {
	getReportCodes,
	getReport,
	getPricelist,
	postUpload,
	getPricelistDetails,
	patchPricelist,
	getSOitemlist,
	getSOlist,
	getSOitemHeader,
	patchRevLeak,
	userTriggerSOCF,
	getSIlist,
	downloadTemp,
	directPrinting
}
export default slice.reducer