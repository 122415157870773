import { combineReducers } from '@reduxjs/toolkit';
import authentication from './authentication';

import user from './administration-user'
import role from './administration-role';
import modulE from './administration-module';
import report from './administration-report';
import quickcode from './administration-quickcode'

import select from './select';

import wms_reporthub from './wms_reporthub';
import wms_converter from './wms_converter';
import hls_converter from './hls_converter';
import wms_stc_mapping from './wms_stc_mapping';

import tms_datasync from './tms_datasync';
import tms_converter from './tms_converter';
import tms_reporthub from './tms_reporthub';

import fms_reporthub from './fms_reporthub';
import fms_salem from './fms_salem';
import fms_autosi from './fms_autosi';
import fms_unsent from './fms_unsent';

// import filters from './filters';
// import dataUpload from './data-upload';
// import dataDownload from './data-download';

const combinedReducers = combineReducers({
	auth				: authentication,

	admin_user			: user,
	admin_role			: role,
	admin_module		: modulE,
	admin_report		: report,
	admin_quickcode		: quickcode,

	select				: select,

	wms_reporthub		: wms_reporthub,
	wms_converter		: wms_converter,
	hls_converter		: hls_converter,
	wms_stc_mapping		: wms_stc_mapping,

	tms_datasync		: tms_datasync,
	tms_reporthub		: tms_reporthub,
	tms_converter		: tms_converter,

	fms_reporthub		: fms_reporthub,
	fms_salem			: fms_salem,
	fms_autosi			: fms_autosi,
	fms_unsent			: fms_unsent

	// dataManagement	: dataManagement,
	// filters			: dfilters,
})

const rootReducer = (state, action) => {
	if (action.type === 'authentication/sign-out/fulfilled')
		state = {}
	return combinedReducers(state, action)
}

export default rootReducer