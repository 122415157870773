import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	Typography,
	Paper,
	Button,
	DialogActions,
	TextareaAutosize
} from '@mui/material';

const RemarksDetails = ({
	isOpen,
	toggle,
	remarks,
	hdr,
	latest
}) => {

	return (
		<Dialog open={isOpen}>
			<DialogTitle>{'SO No. ' + hdr?.si_SONo + ' and SI No. ' + hdr?.si_SIno  }</DialogTitle>
			<DialogContent dividers>
				<Grid item md={12} sx={{ paddingLeft: 1 }}>
					<Grid container component={Paper} variant='container'>
						<Grid item md={12}>

							{/**Dynamically Generate the Report Details */}
							{remarks ?

								<Typography>
									<TextareaAutosize style={{ overflow: "hidden", width: "515px" }}>{remarks}</TextareaAutosize>
								</Typography>
								: <Typography variant='body1' >No {latest ?? 'Latest'} Remarks</Typography>
							}

						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={toggle} color='secondary'>Cancel</Button>
			</DialogActions>
		</Dialog>
	);
}

RemarksDetails.defaultProps = {
	isOpen: false,
	toggle: false,
	remarks: false
}

export default RemarksDetails;