import { createSlice } from '@reduxjs/toolkit';
import { getReportCodes, getReport, postUpload, downloadTemp, getSTCmapping, getSTCmappingDetails, patchSTCmapping, postSTCmapping } from './wms_stc_mapping.thunk';

const initialState = {
	loading: false
}

const slice = createSlice({
	name: 'wms_stc_mapping.',
	initialState,
	reducers: {
		resetAction: () => initialState
	},
	extraReducers: {
		[getReportCodes.pending]: (state, action) => {
			state.loading = true
		},
		[getReportCodes.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getReportCodes.rejected]: (state, action) => {
			state.loading = false
		},
		[getReport.pending]: (state, action) => {
			state.loading = true
		},
		[getReport.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getReport.rejected]: (state, action) => {
			state.loading = false
		},
		[postUpload.pending]: (state, action) => {
			state.loading = true
		},
		[postUpload.fulfilled]: (state, action) => {
			state.loading = false
		},
		[postUpload.rejected]: (state, action) => {
			state.loading = false
		},
		[downloadTemp.pending]: (state, action) => {
			state.loading = true
		},
		[downloadTemp.fulfilled]: (state, action) => {
			state.loading = false
		},
		[downloadTemp.rejected]: (state, action) => {
			state.loading = false
		},
		[getSTCmapping.pending]: (state, action) => {
			state.loading = true
		},
		[getSTCmapping.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getSTCmapping.rejected]: (state, action) => {
			state.loading = false
		},
		[getSTCmappingDetails.pending]: (state, action) => {
			state.loading = true
		},
		[getSTCmappingDetails.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getSTCmappingDetails.rejected]: (state, action) => {
			state.loading = false
		},
		[patchSTCmapping.pending]: (state, action) => {
			state.loading = true
		},
		[patchSTCmapping.fulfilled]: (state, action) => {
			state.loading = false
		},
		[patchSTCmapping.rejected]: (state, action) => {
			state.loading = false
		},
		[postSTCmapping.pending]: (state, action) => {
			state.loading = true
		},
		[postSTCmapping.fulfilled]: (state, action) => {
			state.loading = false
		},
		[postSTCmapping.rejected]: (state, action) => {
			state.loading = false
		},
	}
})

export {
	getReportCodes,
	getReport,
	postUpload,
	downloadTemp,
	getSTCmapping,
	getSTCmappingDetails,
	patchSTCmapping,
	postSTCmapping
}
export default slice.reducer