import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';

const UploadDialog = ({
	name,
	type,
	isOpen,
	toggle,
	onConfirm,
	onUpload
}) => {
	const [state, setState] = React.useState(
		{
			header: [],
			details: []
		}
	);

	React.useEffect(() => {
		setState({
			...state,
			data: []
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	return (
		<Dialog maxWidth='md' open={isOpen}>
			<DialogTitle>File Upload</DialogTitle>
			<Grid component={DialogContent} container rowSpacing={1}>
				<Grid item xs={12}>
					<TextField id={name} fullWidth type='file' onChange={onUpload} />
				</Grid>
			</Grid>
			<DialogActions>
				<Button variant='contained' onClick={toggle} color='secondary'>Cancel</Button>
				<Button variant='contained' onClick={onConfirm}>Confirm</Button>
			</DialogActions>
		</Dialog>
	)
}

UploadDialog.defaultProps = {
	onConfirm: () => { }
}
export default UploadDialog
