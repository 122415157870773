const downloadBase64File = ({
	typeFormat,
	contentBase64,
	filename
}) => {
	const linkSource = `data:${typeFormat};base64,${contentBase64}`;
	const downloadLink = document.createElement('a');
	document.body.appendChild(downloadLink);
	downloadLink.href = linkSource;
	downloadLink.target = '_self';
	downloadLink.download = filename;
	downloadLink.click();
}

export default downloadBase64File