import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Spinner } from '..';
import { downloadBase64File } from '../../helpers/buttons'
import { getReport } from '../../store/fms_salem/fms_salem.slice';

const OpenOneTextBox = ({
	isOpen,
	toggle,
	state
}) => {

	const dispatch = useDispatch();
	const handleChange = (e) => {
		setPrint({
			...state,
			printValue: print.printValue,
			[e.target.name]: e.target.value
		})
	}
	const handleDownloadExcel = () => {
		setIsDisabled(true);
		toggle();
		dispatch(getReport({
			route: 'sales-invoice',
			downloadType: 'xlsxsalesinvoice',
			data: {
				...state,
				...print,
				report: { value: 'Sales Invoice Print' }
			}
		}))
			.unwrap()
			.then(result => {
				if (result.status === 200) {
					//## type format of Base64
					const typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
					const contentBase64 = result.data.contents;
					const filename = result.data.filename;
					downloadBase64File({
						typeFormat: { typeFormat },
						contentBase64,
						filename
					})
					cancelButton()
				}
			}).catch(e => {
				setIsDisabled(!isOpen);
			});
	}

	const [print, setPrint] = React.useState({
		...state,
		printError: false,
		printValue: ''
	})
	const [isDisabled, setIsDisabled] = React.useState(false)
	const cancelButton =() =>{
		toggle();
		setIsDisabled(!isOpen);
	}
	return (
		<Dialog open={isOpen}>
			<DialogTitle>Enter Sales Invoice No.</DialogTitle>
			<DialogContent dividers>
				<div>
					<Spinner reducer='itm' />
					<Grid container spacing={2}>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='printValue'
								error={print.printError}
								variant='outlined'
								label='Enter Sales Invoice No.'
								value={print.printValue}
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={cancelButton} color='secondary' disabled={isDisabled}>Cancel</Button>
				<Button variant='contained' onClick={handleDownloadExcel} disabled={isDisabled}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

OpenOneTextBox.defaultProps = {
	isOpen: false,
	toggle: false,
	state: {}
}

export default OpenOneTextBox;