import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Spinner } from '..';
import { postAutoSI } from '../../store/fms_autosi/fms_autosi.slice';

const CreateAutoSIDialog = ({
	isOpen,
	toggle
}) => {

	const dispatch = useDispatch();

	const [state, setState] = React.useState({
		isAutoSIError	:false,
		slm_location			: '',
		slm_customer			: '',
		slm_si_from				: '',
		slm_si_to				: '',
		slm_bir_id				: '',
		slm_ascii_loc			: '',
	})

	const handleCreate = () => {
		let hasAutoSI = false;
		if (state.slm_ascii_loc.replace(/ /g, '') === '' || state.slm_bir_id.replace(/ /g, '') === '' || state.slm_customer.replace(/ /g, '') === '' || state.slm_location.replace(/ /g, '') === '' || state.slm_si_from.replace(/ /g, '') === '' || state.slm_si_to.replace(/ /g, '') === '') {
			hasAutoSI = true;
		}

		setState({
			...state,
			isAutoSIError: hasAutoSI
		})

		if (!hasAutoSI) {
			dispatch(postAutoSI({
				route: '',
				data: {
					slm_location	:	state.slm_location,
					slm_customer	:	state.slm_customer,
					slm_si_from		:	state.slm_si_from,
					slm_si_to		:	state.slm_si_to,
					slm_bir_id		:	state.slm_bir_id,
					slm_ascii_loc	:	state.slm_ascii_loc
				}
			}))

			setState({
				...state,
				isAutoSIError	:	false,
				slm_location	:	'',
				slm_customer	:	'',
				slm_si_from		:	'',
				slm_si_to		:	'',
				slm_bir_id		:	'',
				slm_ascii_loc	:	'',
			})
			toggle();
		}
	}

	const handleChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}

	return (
		<Dialog open={isOpen}>
			<DialogTitle>Sales Invoice Range</DialogTitle>
			<DialogContent dividers>
				<div>
					<Spinner reducer='rc' />
					<Grid container spacing={2}>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='slm_location'
								error={state.isAutoSIError}
								variant='outlined'
								label='Warehouse Location'
								value={state.slm_location}
								onChange={handleChange}
								helperText='ex. CEBU'
							/>
						</Grid>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='slm_customer'
								error={state.isAutoSIError}
								variant='outlined'
								label='Customer ID'
								value={state.slm_customer}
								onChange={handleChange}
								helperText='ex. DDL'
							/>
						</Grid>
						<Grid item container>
							<TextField
								fullWidth
								name='slm_ascii_loc'
								error={state.isAutoSIError}
								variant='outlined'
								label='ASCII Location Code'
								value={state.slm_ascii_loc}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								name='slm_si_from'
								error={state.isAutoSIError}
								variant='outlined'
								label='Sales Invoice From'
								value={state.slm_si_from}
								onChange={handleChange}
								helperText='ex. PAS-0000001'
							/>
						</Grid>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='slm_si_to'
								error={state.isAutoSIError}
								variant='outlined'
								label='Sales Invoice To'
								value={state.slm_si_to}
								onChange={handleChange}
								helperText='ex. PAS-0000500'
							/>
						</Grid>
						<Grid item container>
							<TextField
								fullWidth
								name='slm_bir_id'
								error={state.isAutoSIError}
								variant='outlined'
								label='BIR ID'
								value={state.slm_bir_id}
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={toggle} color='secondary'>Cancel</Button>
				<Button variant='contained' onClick={handleCreate}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CreateAutoSIDialog;