/* eslint-disable no-useless-escape */
/* eslint-disable dot-location */
import React from 'react';
import Spinner from '../../components/spinner/spinner'
import { Toolbar } from '../../components/toolbar';
import { Table } from '../../components/table';
import {
	Grid,
	Paper,
	Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Input, DatePicker } from '../../components/inputs'
import { MasterSelect } from '../../components/select'
import {
	getUnsentList,
	getReportCodes,
	postUnsent
} from '../../store/fms_unsent/fms_unsent.slice';
import moment from 'moment'
import { RemarksDetailsDialog } from '../../components/dialogs';

const SalemUnsent = ({ routes }) => {
	return (
		<View />
	);
}

const View = () => {

	const [remarksDialog, setRemarksDialog] = React.useState(false);
	const [rmks, setRemarks] = React.useState({ remarks: null });	
	const { loading } = useSelector(state => state.fms_unsent)
	const dispatch = useDispatch();
	const [state, setState] = React.useState({
		dateFrom: new Date(),
		dateTo: new Date()
	})

	React.useEffect(() => {
		dispatch(getReportCodes({
			route: 'unsent/',
			report_id: 'SLM-INV-MNGT-03'
		}))
		.unwrap()
		.then(result => {
			/** Get the source code of the report (na string in json format) */
			let report_source_code = result?.data?.data[0]?.report_source_code || 'pamparampampam';

			/** Check if source code (na string) is JSON.parsable */
			if (/^[\],:{}\s]*$/.test(report_source_code.replace(/\\["\\\/bfnrtu]/g, '@').
				replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
				replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
				setState({
					...state,
					source_code: JSON.parse(report_source_code)
				})
			}
			else {
				setState({
					...state,
					source_code: {}
				})
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, ['SLM-INV-MNGT-03'])

	const fetchData = React.useCallback(({ pageIndex, pageSize, filters }, callBack) => {
		dispatch(getUnsentList({
			route: 'unsent/',
			page: pageIndex,
			totalPage: pageSize,
			orderBy: 'createdAt,DESC',
			filters: {
				...filters,
				si_warehouseId: state?.si_warehouseId?.label,
				si_customerId: state?.si_customer?.label,
				si_status: state?.si_status?.label,
				dateFrom: state?.dateFrom,
				dateTo: state?.dateTo
			}
		}))
		.unwrap()
		.then(result => {
			callBack(result)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state])

	const handleSelectChange = (e, name) => {
		setState({
			...state,
			[name]: e
		})
	}

	const handleChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}

	const handleUnsent = () =>{
		dispatch(postUnsent({
			route: 'unsent/',
			data: ''
		}))
		.unwrap()
		.then(result => {
			if (result.status === 200) {
				
			}
		})
	}

	const toggleRemarksDialog = React.useCallback(() => {
		setRemarksDialog(!remarksDialog);
		if (remarksDialog === true) {
			setRemarks({ remarks: false });
		}
	}, [remarksDialog]);

	const columns = React.useMemo(() => [
		{
			Header: 'Warehouse Location',
			accessor: 'si_warehouseId'
		},
		{
			Header: 'Principal (Customer)',
			accessor: 'si_customerId'
		},
		{
			Header:'Attempted Date',
			accessor:'createdAt',
			width:140,
			Cell:props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
			}
		},
		{
			Header: 'Last Modified',
			accessor:'updatedAt',
			width:140,
			Cell:props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
			}
		},
		{
			Header:'RDD',
			accessor:'si_RDD',
			width:140,
			Cell:props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
			}
		},
		{
			Header: 'SO No',
			accessor: 'si_SONo'
		},
		{
			Header: 'Assigned Sales Invoice',
			accessor: 'si_SIno'
		},
		{
			Header: 'Updated Sales Invoice',
			accessor: 'si_SI_update'
		},
		{
			Header: 'PO Reference',
			accessor: 'si_refNo'
		},
		{
			Header: 'Status',
			accessor: 'si_status'
		},
		{
			Header: 'Last Status',
			accessor: 'si_unsent_update'
		},
		{
			Header: 'Remarks',
			accessor: 'si_remarks',
			Cell: props => {
				const onClick = () => {
					let remarks = `${props?.value ?? ''}`;
					setRemarks({
						remarks, hdr: {
							si_SONo: props?.row?.original?.si_SONo,
							si_SIno: props?.row?.original?.si_SIno
						},
						lastest: true
					})
					toggleRemarksDialog();
				}
				return (<Typography sx={{ color: '#CC6400' }} style={{ cursor: "pointer" }} onClick={onClick} >{'Remarks'}</Typography>)
			}
		},
		{
			Header: 'Lastest Remarks',
			accessor: 'si_update_remarks',
			Cell: props => {
				const onClick = () => {
					let remarks = `${props?.value ?? ''}`;
					setRemarks({
						remarks, hdr: {
							si_SONo: props?.row?.original?.si_SONo,
							si_SIno: props?.row?.original?.si_SIno
						}, 
						lastest: true
					})
					toggleRemarksDialog();
				}				
				return (<Typography sx={{ color: '#CC6400' }} style={{ cursor: "pointer" }} onClick={onClick} >{'Latest Remarks'}</Typography>)
			}
		},
		{
			Header: 'Customer',
			Cell: props => {
				return (props.row.original.si_storeCode+' - '+props.row.original.si_consignee_name)
			}
		},
		{
			Header: 'VATable Sales Gross of Discount',
			accessor: 'si_vat_gross_dc'
		},
		{
			Header: 'Less: Total Discounts & Others',
			accessor: 'si_total_discount'
		},
		{
			Header: 'Total Sales',
			accessor: 'si_total_sales'
		},
		{
			Header: 'VAT',
			accessor: 'si_vat'
		},
		{
			Header: 'Total Amount Due',
			accessor: 'si_total_amount_due'
		},
	], [toggleRemarksDialog])

	return (
		<Grid container rowSpacing={1}>
			<Spinner loading={loading} />
			<Grid item md={12}>
				<Toolbar
					label='Unsent Sales Invoice in ASCII'
					isDynamicButton={state?.source_code?.buttons?.isDynamicButton || false}
					onDynamicButton={handleUnsent}
					textDynamicButton='Re-send Sales Invoice'
					colorDynamicButton='synch'
				/>
			</Grid>
			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>

					{/**Dynamically Generate Select Components  */}
					{typeof state?.source_code?.dropdowns === 'object' ?
						state?.source_code?.dropdowns.map((foo, i) => (
							<Grid item md={12} key={i}>
								<MasterSelect
									size={12}
									key={i}
									paddingLeft={1}
									paddingRight={1}
									fullWidth
									label={foo.label}
									placeholder={foo.placeholder}
									name={foo.name}
									route={foo.route}
									type={foo.type}
									module_code='salem reporthub'
									value={state[foo.name]}
									handleChange={handleSelectChange}
								/>
							</Grid>
						)) : null
					}

					{/**Dynamically Generate Text Fields Components  */}
					{typeof state?.source_code?.textfields === 'object' ?
						state?.source_code?.textfields.map((foo, i) => (
							<Input
								key={i}
								fullWidth
								size={12}
								variant='outlined'
								isLabelVisible={true}
								label={foo.label}
								required={foo.isRequired}
								name={foo.name}
								value={state[foo.name]}
								handleChange={handleChange}
							/>
						)) : null
					}

					{/**Dynamically Generate Dates Components  */}
					{typeof state?.source_code?.datefields === 'object' ?
						state?.source_code?.datefields.map((foo, i) => (
							<DatePicker
								key={i}
								size={6}
								variant='outlined'
								isLabelVisible={true}
								label={foo.label}
								name={foo.name}
								value={state[foo.name]}
								handleChange={handleChange}
							/>
						)) : null
					}

				</Grid>
			</Grid>
			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>
					<Table
						loading={loading}
						columns={columns}
						fetchData={fetchData}
					/>
				</Grid>
			</Grid>
			<RemarksDetailsDialog isOpen={remarksDialog} remarks={rmks.remarks} hdr={rmks.hdr} latest={rmks.latest} toggle={() => { toggleRemarksDialog() }} />
		</Grid>
	)
}

export default SalemUnsent;