import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Spinner } from '..';
import { Switch } from '../inputs';
import { postLocMapping } from '../../store/wms_loc_mapping/wms_loc_mapping.thunk';
import { MasterSelect } from '../select';

const CreateLocMapping = ({
	isOpen,
	toggle,
}) => {

	const dispatch = useDispatch();

	const [state, setState] = React.useState({
		isMappingError: false,
		whLocation: '',
		principal: '',
		dmg_loc: '',
		route: '',
		mapping_status: true
	});

	const handleUpdate = () => {
		dispatch(postLocMapping({
			route: 'LocMapping',
			data: {
				whLocation: state.whLocation.value,
				principal: state.principal.value,
				dmg_loc: state.dmg_loc,
				route: state.route,
				mapping_status: state.mapping_status
			}
		}))

		setState({
			...state,
			isMappingError: false,
			whLocation: '',
			principal: '',
			dmg_loc: '',
			route: '',
			mapping_status: true
		})
		toggle();
	}

	const handleChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}

	const handleSelectChange = (e, name) => {
		setState({
			...state,
			[name]: e
		})
	}

	return (
		<Dialog open={isOpen}>
			<DialogTitle>Create STC Store Mapping</DialogTitle>
			<DialogContent dividers>
				<div>
					<Spinner reducer='itm' />
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<MasterSelect
								fullWidth
								placeholder='Warehouse Location'
								label='Warehouse Location'
								name='whLocation'
								route='quickcode'
								type='HLS Warehouse Location'
								value={state.whLocation || ''}
								handleChange={handleSelectChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<MasterSelect
								fullWidth
								placeholder='Principal (Customer)'
								label='Principal (Customer)'
								name='principal'
								route='quickcode'
								type='HLS Principal'
								value={state.principal || ''}
								handleChange={handleSelectChange}
							/>
						</Grid>

						<Grid item container>
							<TextField
								required
								fullWidth
								name='dmg_loc'
								variant='outlined'
								label='Damage Location'
								value={state.dmg_loc}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								fullWidth
								name='route'
								variant='outlined'
								label='Route'
								value={state.route}
								onChange={handleChange}
							/>
						</Grid>

						<Grid item md={2} xs={12}>
							<Switch isLabelVisible
								label='Status'
								name='mapping_status'
								checked={state.mapping_status}
								handleChange={(e) => setState({ ...state, mapping_status: e.target.checked })} />
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={toggle} color='secondary'>Cancel</Button>
				<Button variant='contained' onClick={handleUpdate}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

CreateLocMapping.defaultProps = {
	isOpen: false,
	toggle: false,
	id: 'Unknown ID'
}

export default CreateLocMapping;