import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../helpers/api';
import { toast } from 'react-toastify';

const baseURL = '/wms';
const headers = {
	'Content-Type': 'application/json',
}

const getReportCodes = createAsyncThunk('wms-report-sourceCode/get',
	async ({ route, report_id }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}/report-sourcecode`, {
				params: {
					report_id
				}
			})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getReport = createAsyncThunk('wms-report/post',
	async ({ route, data, downloadType }, { rejectWithValue }) => {
		try {
			let res;
			if (data.report === null || typeof data.report.value !== 'string') {
				toast.error('No report selected!')
			}
			else {
				res = await API({
					requestHeaders: {
						...headers
					}
				}).post(`${baseURL}/${route}/${downloadType}`, {
					data
				})
			}

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const postUpload = createAsyncThunk('loc-mapping/bulk/post',
	async ({ route, data }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).post(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success('Successfully Uploaded!')
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const downloadTemp = createAsyncThunk('loc-mapping/downloadUploadtemplate/post',
	async ({ route, data }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).post(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success(`Download successfully!`)
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getLocMapping = createAsyncThunk('loc-mapping/get',
	async ({ route, page, totalPage, orderBy, filters }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}`, {
				params: {
					page,
					totalPage,
					orderBy,
					...filters
				}
			})

			return res.data
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getLocMappingDetails = createAsyncThunk('loc-mapping/getDetails',
	async ({ route, filters }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}`, {
				params: {
					...filters
				}
			})

			return res.data
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const patchLocMapping = createAsyncThunk('loc-mapping/patch',
	async ({ route, data }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).patch(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success('Location Mapping updated!')
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const postLocMapping = createAsyncThunk('loc-mapping/post',
	async ({ route, data }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).post(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success('Location Mapping created!')
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

export {
	getReportCodes,
	getReport,
	postUpload,
	downloadTemp,
	getLocMapping,
	getLocMappingDetails,
	patchLocMapping,
	postLocMapping
}