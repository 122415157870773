import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Spinner } from '..';
import { downloadBase64File } from '../../helpers/buttons'
import { getReport } from '../../store/fms_salem/fms_salem.slice';

const OpenOneTextBox = ({
	isOpen,
	toggle,
	cancel,
	checkedSO
}) => {

	const dispatch = useDispatch();
	const handleChange = (e) => {
		let c = checkedSO.checkedSOList.find(q => `${Object.keys(q)}` === `${e.target.name}`)
		setPrint({
			...print,
			printValue: {
				...print.printValue,
				[e.target.name]: e.target.value
			},
			printDetails: {
				...print.printDetails,
				...c
			}
		})
	}
	const handleDownloadExcel = () => {
		setIsDisabled(true);
		toggle();
		let toPrint = print;
		dispatch(getReport({
			route: 'sales-invoice',
			downloadType: 'xlsxsalesinvoicebulk',
			data: {
				...toPrint,
				report: { value: 'Sales Invoice Print' }
			}
		}))
			.unwrap()
			.then(result => {
				if (result.status === 200) {
					//## type format of Base64
					const typeFormat = 'application/x-zip-compressed';
					const contentBase64 = result.data.contents;
					const filename = result.data.filename;
					downloadBase64File({
						typeFormat: { typeFormat },
						contentBase64,
						filename
					})
					cancelButton();
				}
			}).catch(e => {
				setIsDisabled(!isOpen);
			})
	}

	const [print, setPrint] = React.useState({
		printError: false,
		printValue: {}
	})


	const [isDisabled, setIsDisabled] = React.useState(false)
	const cancelButton = () => {
		cancel();
		setIsDisabled(!isOpen);
		setPrint({
			printError: false,
			printValue: {}
		});
	}
	return (
		<Dialog open={isOpen}>
			<DialogTitle>Enter Sales Invoice No.</DialogTitle>
			<DialogContent dividers>
				<div>
					<Spinner reducer='itm' />
					<Grid container spacing={2}>
						{Object.keys(checkedSO?.checkedSOList).length > 0 ? checkedSO.checkedSOList.map((foo, i) => (
							<Grid item container>
								<TextField
									key={i}
									required
									fullWidth
									name={foo[Object.keys(foo)].so_conf_id}
									error={print.printError}
									variant='outlined'
									label={'Enter ' + Math.ceil(foo[Object.keys(foo)]?.conf_slm_so?.so_skuLineCount / 28) + ' Sales Invoice No. of ' + foo[Object.keys(foo)].docNo}
									value={print.printValue[foo[Object.keys(foo)].so_conf_id]}
									onChange={handleChange}
								/>
							</Grid>
						)) : null
						}
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={cancelButton} color='secondary' disabled={isDisabled}>Cancel</Button>
				<Button variant='contained' onClick={handleDownloadExcel} disabled={isDisabled}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

OpenOneTextBox.defaultProps = {
	isOpen: false,
	toggle: false,
	checkedSO: {}
}

export default OpenOneTextBox;