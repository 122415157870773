import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../helpers/api';
import { toast } from 'react-toastify';

const baseURL = '/salem/auto-salesinvoice';
const headers = {
	'Content-Type': 'application/json',
}

const getReportCodes = createAsyncThunk('fms-report-sourceCode/get',
	async ({ route, report_id }, { rejectWithValue }) => {
		try {

			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}/report-sourcecode`, {
				params: {
					report_id
				}
			})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getAutoSI = createAsyncThunk('slm-autosi/get',
	async ({ route, page, totalPage, orderBy, filters }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}`, {
				params: {
					page,
					totalPage,
					orderBy,
					...filters
				}
			})
			return res.data
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const postAutoSI = createAsyncThunk('slm-autosi/post',
	async ({ route, data }, { rejectWithValue }) => {
		// console.log(`${baseURL}/${route}`);
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).post(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success('Successfully Uploaded!')
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const patchAutoSI = createAsyncThunk('slm-autosi/patch',
	async ({ route, data }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).patch(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success('Successfully Updated!')
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getAutoSIdetails = createAsyncThunk('slm-autosi/getDetails',
	async ({ route, filters }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}`, {
				params: {
					...filters
				}
			})

			return res.data
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)
export {
	getReportCodes,
	getAutoSI,
	postAutoSI,
	patchAutoSI,
	getAutoSIdetails
}
