import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Spinner } from '..';
import { Switch } from '../inputs';
import { postSTCmapping } from '../../store/wms_stc_mapping/wms_stc_mapping.thunk';
import { MasterSelect } from '../../components/select';

const CreateSTCmapping = ({
	isOpen,
	toggle,
}) => {

	const dispatch = useDispatch();

	const [state, setState] = React.useState({
		isMappingError: false,
		principal: '',
		store_code: '',
		store_group: '',
		vendor_code: '',
		mapping_status: true,
		stc_code: ''
	});

	const handleUpdate = () => {
		dispatch(postSTCmapping({
			route: 'stcmapping',
			data: {
				principal: state.principal.value,
				store_code: state.store_code,
				store_group: state.store_group,
				vendor_code: state.vendor_code,
				mapping_status: state.mapping_status,
				stc_code: state.stc_code
			}
		}))

		setState({
			...state,
			isMappingError: false,
			principal: '',
			store_code: '',
			store_group: '',
			vendor_code: '',
			mapping_status: true,
			stc_code: ''
		})
		toggle();
	}

	const handleChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}

	const handleSelectChange = (e, name) => {
		setState({
			...state,
			[name]: e
		})
	}

	return (
		<Dialog open={isOpen}>
			<DialogTitle>Create STC Store Mapping</DialogTitle>
			<DialogContent dividers>
				<div>
					<Spinner reducer='itm' />
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<MasterSelect
								disabled
								fullWidth
								placeholder='Principal (Customer)'
								label='Principal (Customer)'
								name='principal'
								route='quickcode'
								type='HLS Principal'
								value={state.principal || ''}
								handleChange={handleSelectChange}
							/>
						</Grid>

						<Grid item container>
							<TextField
								fullWidth
								name='store_code'
								variant='outlined'
								label='Store Code'
								value={state.store_code}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='store_group'
								variant='outlined'
								label='Store Group'
								value={state.store_group}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='vendor_code'
								variant='outlined'
								label='Vendor Code'
								value={state.vendor_code}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField

								required
								fullWidth
								name='stc_code'
								error={state.isMappingError}
								variant='outlined'
								label='STC Code'
								value={state.stc_code}
								onChange={handleChange}
							/>
						</Grid>

						<Grid item md={2} xs={12}>
							<Switch isLabelVisible
								label='Status'
								name='mapping_status'
								checked={state.mapping_status}
								handleChange={(e) => setState({ ...state, mapping_status: e.target.checked })} />
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={toggle} color='secondary'>Cancel</Button>
				<Button variant='contained' onClick={handleUpdate}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

CreateSTCmapping.defaultProps = {
	isOpen: false,
	toggle: false,
	id: 'Unknown ID'
}

export default CreateSTCmapping;