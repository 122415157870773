import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Spinner } from '..';
import { Switch } from '../inputs';
import { getAutoSIdetails, patchAutoSI } from '../../store/fms_autosi/fms_autosi.slice';

const UpdateAutoSIDialog = ({
	isOpen,
	toggle,
	id
}) => {

	const dispatch = useDispatch();

	const [state, setState] = React.useState({
		isAutoSIError			:false,
		slm_location			: '',
		slm_customer			: '',
		slm_si_from				: '',
		slm_si_to				: '',
		slm_bir_id				: '',
		slm_ascii_loc			: '',
		slm_status				: true
	})

	const handleUpdate = () => {
		let hasError = false
		if (state.rc_code === '' || state.rc_type === '' || state.rc_type === '') {
			hasError = true
		}
		setState({
			...state,
			isAutoSIError: hasError
		})

		if (!hasError) {
			dispatch(patchAutoSI({
				route: '',
				data: {
					id				: state.id,
					slm_location	: state.slm_location,
					slm_customer	: state.slm_customer,
					slm_si_from		: state.slm_si_from,
					slm_si_to		: state.slm_si_to,
					slm_bir_id		: state.slm_bir_id,
					slm_ascii_loc	: state.slm_ascii_loc,
					slm_status		: state.slm_status
				}
			}))

			setState({
				...state,
				isAutoSIError			: false,
				slm_location			: '',
				slm_customer			: '',
				slm_si_from				: '',
				slm_si_to				: '',
				slm_bir_id				: '',
				slm_ascii_loc			: '',
				slm_status				: true
			})
			toggle();
		}
	}

	React.useEffect(() => {
		if (id !== null && id !== 'unknown quick code id') {
			dispatch(getAutoSIdetails({
				route: 'details',
				filters: { id }
			}))
				.unwrap()
				.then(result => {
					setState({
						...state,
						...result.data[0]
					})
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<Dialog open={isOpen}>
			<DialogTitle>Update Sales Invoice</DialogTitle>
			<DialogContent dividers>
			<div>
					<Spinner reducer='rc' />
					<Grid container spacing={2}>
						<Grid item container>
						<TextField
								disabled
								fullWidth
								name='slm_location'
								variant='outlined'
								label='Warehouse Location'
								value={state.slm_location}
							/>
						</Grid>
						<Grid item container>
						<TextField
								disabled
								fullWidth
								name='slm_customer'
								variant='outlined'
								label='Customer ID'
								value={state.slm_customer}
							/>
						</Grid>
						<Grid item container>
						<TextField
								disabled
								fullWidth
								name='slm_ascii_loc'
								variant='outlined'
								label='ASCII Location Code'
								value={state.slm_ascii_loc}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								disabled
								fullWidth
								name='slm_si_from'
								variant='outlined'
								label='Sales Invoice From'
								value={state.slm_si_from}
							/>
						</Grid>
						<Grid item container>
							<TextField
								disabled
								fullWidth
								name='slm_si_to'
								variant='outlined'
								label='Sales Invoice To'
								value={state.slm_si_to}
							/>
						</Grid>
						<Grid item container>
							<TextField
								fullWidth
								name='slm_bir_id'
								variant='outlined'
								label='BIR ID'
								value={state.slm_bir_id}
							/>
						</Grid>
						<Grid item md={2} xs={12}>
							<Switch isLabelVisible
								label='Status'
								name='slm_status'
								checked={state.slm_status}
								handleChange={(e) => setState({ ...state, slm_status: e.target.checked })} />
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={toggle} color='secondary'>Cancel</Button>
				<Button variant='contained' onClick={handleUpdate}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

UpdateAutoSIDialog.defaultProps = {
	isOpen		: false,
	toggle		: false,
	id		: 'unknown quick code id'
}

export default UpdateAutoSIDialog;