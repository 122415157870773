/* eslint-disable no-useless-escape */
/* eslint-disable dot-location */
import React from 'react';
import Spinner from '../../components/spinner/spinner'
import { Toolbar } from '../../components/toolbar';
import {
	Grid,
	Paper,
	Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

//import moment from 'moment';
import path from 'path'
import { Input, DatePicker } from '../../components/inputs'
import { downloadBase64File, base64toBlob } from '../../helpers/buttons'
import { MasterSelect } from '../../components/select'
import { getReportCodes, postUpload, downloadTemp } from '../../store/wms_converter/wms_converter.slice';

const KairosConverter = ({ routes }) => {
	return (
		<View />
	);
}

const View = () => {

	const { loading } = useSelector(state => state.wms_converter)
	const dispatch = useDispatch();
	const [state, setState] = React.useState({
		report: '',
		filters: {}
	})

	const handleReportChange = (e, name) => {
		/**Reset the state if report dropdown is changed*/
		setState({
			[name]: e
		})
	}

	const downloadTemplate = () => {
		//console.log(data);
		dispatch(downloadTemp({
			route: 'converter/downloadUploadtemplate',
			data: {
				fileName: state?.source_code?.fileName
			}
		}))
		.unwrap()
		.then(result => {
			if (result.status === 200) {
				//## type format of Base64
				const typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
				const contentBase64 = result.data.contents;
				const filename = result.data.filename;
				downloadBase64File({
					typeFormat: { typeFormat },
					contentBase64,
					filename
				})
			}
		})
	};
	
	const handleUpload = (e) => {
		setuploadState({
			selectedFile: e.target.files[0],
			loaded: 0,
		})
		setfetchDataState({
			...fetchDataState,
			selectedFile: fetchDataState?.selectedFile || e.target.files[0].name
		})
	}

	const handleConfirm = () => {
		toggleUploadDialog();
		var file = uploadState.selectedFile;
		//const now = moment(new Date()).format('MM/DD/YYYY');
		if (file != null) {
			var reader = new FileReader();
			reader.onload = function () {
				const datafile = reader.result;
				//console.log(data);
				dispatch(postUpload({
					route: 'converter',
					data: {
						...state,
						fileName: file.name,
						datafile
					}
				}))
					.unwrap()
					.then(result => {
						if (result.status === 200) {
							//## type format of Base64
							let typeFormat = 'application/pdf';
							const contentBase64 = result.data.contents;
							const filename = result.data.filename;
							const ext = path.extname(filename);
							if (ext.toLowerCase() === '.pdf') {
								// file is a File object, this will also take a blob
								const dataUrl = window.URL.createObjectURL(base64toBlob(contentBase64, typeFormat))

								// Open the window
								const pdfWindow = window.open(dataUrl);

								// Call print on it
								pdfWindow.print();
							}
							else {
								typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
								downloadBase64File({
									typeFormat: { typeFormat },
									contentBase64,
									filename
								})
							}
							setControls({
								...controls,
								uploadDialog: !controls.uploadDialog
							})
						}
					})
			};
			reader.readAsDataURL(file);
		}
	}

	const toggleUploadDialog = () => {
		//console.log(1,controls.uploadDialog)
		setControls({
			...controls,
			uploadDialog: !controls.uploadDialog
		})
	}
	const [controls, setControls] = React.useState({
		uploadDialog: false
	});
	/* upload start */
	const [uploadState, setuploadState] = React.useState({
		selectedFile: null,
		loaded: 0
	})

	const [fetchDataState, setfetchDataState] = React.useState({})

	const handleSelectChange = (e, name) => {
		setState({
			...state,
			[name]: e
		})
	}

	const handleChange = (e) => {
		// console.log('e',e.target)
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}

	/**Fetching the source code on the report*/
	React.useEffect(() => {
		if (state.report !== '' && state.report !== null) {
			dispatch(getReportCodes({
				route: 'converter',
				report_id: state.report?.value
			}))
				.unwrap()
				.then(result => {
					/** Get the source code of the report (na string in json format) */
					let report_source_code = result?.data?.data[0]?.report_source_code || 'pamparampampam';

					/** Check if source code (na string) is JSON.parsable */
					if (/^[\],:{}\s]*$/.test(report_source_code.replace(/\\["\\\/bfnrtu]/g, '@').
						replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
						replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
						setState({
							...state,
							source_code: JSON.parse(report_source_code)
						})
					}
					else {
						setState({
							...state,
							source_code: {}
						})
					}
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.report])

	return (
		<Grid container rowSpacing={1}>
			<Spinner loading={loading} />
			<Grid item md={12}>
				<Toolbar
					label='Kairos Converter Hub'
					isUpload={state?.source_code?.buttons?.isUpload || false}
					onUpload={handleUpload}
					onConfirm={handleConfirm}
					toggleUploadDialog={toggleUploadDialog}
					isOpen={controls.uploadDialog}
					isGenerate={state?.source_code?.buttons?.isGenerate || false}
					isDynamicButton={state?.source_code?.buttons?.isDynamicButton || false}
					textDynamicButton={state?.source_code?.text?.textDynamicButton || 'Download'}
					colorDynamicButton={'spreadsheet'}
					onDynamicButton={downloadTemplate}
				/>
			</Grid>
			<Grid item md={5}>
				<Grid container component={Paper} variant='container'>
					<Grid item md={12}>
						<MasterSelect
							paddingLeft={1}
							paddingRight={1}
							fullWidth
							label='Report Name'
							placeholder='Report Name'
							name='report'
							route='administration'
							type='report'
							module_code='wms converter'
							value={state.report || ''}
							handleChange={handleReportChange}
						/>
					</Grid>

					{/**Dynamically Generate Select Components */}
					{typeof state?.source_code?.dropdowns === 'object' ?
						state?.source_code?.dropdowns.map((foo, i) => (
							<Grid item md={12}>
								<MasterSelect
									key={i}
									paddingLeft={1}
									paddingRight={1}
									fullWidth
									label={foo.label}
									placeholder={foo.placeholder}
									name={foo.name}
									route={foo.route}
									type={foo.type}
									module_code='wms converter'
									value={state[foo.name]}
									handleChange={handleSelectChange}
								/>
							</Grid>
						)) : null
					}

					{/**Dynamically Generate Text Fields Components */}
					{typeof state?.source_code?.textfields === 'object' ?
						state?.source_code?.textfields.map((foo, i) => (
							<Input
								key={i}
								fullWidth
								size={12}
								variant='outlined'
								isLabelVisible={true}
								label={foo.label}
								required={foo.isRequired}
								name={foo.name}
								value={state[foo.name]}
								handleChange={handleChange}
							/>
						)) : null
					}

					{/**Dynamically Generate Dates Components */}
					{typeof state?.source_code?.datefields === 'object' ?
						state?.source_code?.datefields.map((foo, i) => (
							<DatePicker
								key={i}
								size={6}
								variant='outlined'
								isLabelVisible={true}
								label={foo.label}
								name={foo.name}
								value={state[foo.name]}
								handleChange={handleChange}
							/>
						)) : null
					}

				</Grid>
			</Grid>
			
			<Grid item md={7} sx={{ paddingLeft: 1 }}>
				<Grid container component={Paper} variant='container'>
					<Grid item md={12}>
						{/**Dynamically Generate the Report Details */}
						{typeof state?.source_code?.descriptions === 'object' ?
							state?.source_code?.descriptions.map((foo, i) => (
								<Grid item md={12}>
									<Typography>
										<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>{Object.keys(foo)[0]}</Typography>
										<Typography sx={{ paddingLeft: 2 }} variant='subtitle2' gutterBottom>{foo[Object.keys(foo)[0]]}</Typography>
									</Typography>
								</Grid>
							)) : <Typography variant='body1' >No details found.</Typography>
						}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
export default KairosConverter;