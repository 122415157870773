import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../helpers/api';
import { toast } from 'react-toastify';

const baseURL = '/salem';
const headers = {
	'Content-Type': 'application/json',
}

const getReportCodes = createAsyncThunk('fms-report-sourceCode/get',
	async ({ route, report_id }, { rejectWithValue }) => {
		try {

			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}/report-sourcecode`, {
				params: {
					report_id
				}
			})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const postUpload = createAsyncThunk('data-management/bulkupload/post',
	async ({ route, data }, { rejectWithValue }) => {
		// console.log(`${baseURL}/${route}`);
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).post(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						const ts = result.data.totalsuccess;
						const ti = result.data.totalitems;
						toast.success(`${ts} out of ${ti} uploaded!`)
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const postPricelist = createAsyncThunk('data-management/post',
	async ({ route, data }, { rejectWithValue }) => {
		// console.log(`${baseURL}/${route}`);
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).post(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success('Successfully Uploaded!')
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getPricelist = createAsyncThunk('data-management/get',
	async ({ route, page, totalPage, orderBy, filters }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}`, {
				params: {
					page,
					totalPage,
					orderBy,
					...filters
				}
			})
			return res.data
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getReport = createAsyncThunk('fms-report/post',
	async ({ route, data, downloadType }, { rejectWithValue }) => {
		try {
			let res;
			if (data.report === null || typeof data.report.value !== 'string') {
				toast.error('No report selected!')
			}
			else {
				res = await API({
					requestHeaders: {
						...headers
					}
				}).post(`${baseURL}/${route}/${downloadType}`, {
					data
				})
			}

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getPricelistDetails = createAsyncThunk('data-management/getDetails',
	async ({ route, filters }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}`, {
				params: {
					...filters
				}
			})

			return res.data
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const patchPricelist = createAsyncThunk('data-management/patch',
	async ({ route, data }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).patch(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success('Pricelist deactivated!')
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const patchRevLeak = createAsyncThunk('sales-invoice/items/revenueleak/patch',
	async ({ route, data }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).patch(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success('Ready for Print!')
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getSOitemlist = createAsyncThunk('sales-invoice/items/get',
	async ({ route, page, totalPage, orderBy, filters }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}`, {
				params: {
					page,
					totalPage,
					orderBy,
					...filters
				}
			})

			return res.data
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getSOlist = createAsyncThunk('sales-invoice/get',
	async ({ route, page, totalPage, orderBy, filters }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}`, {
				params: {
					page,
					totalPage,
					orderBy,
					...filters
				}
			})

			return res.data
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getSOitemHeader = createAsyncThunk('sales-invoice/get/item/header',
	async ({ route, filters }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}`, {
				params: {
					...filters
				}
			})
			return res.data
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const userTriggerSOCF = createAsyncThunk('datahub/userTriggerSOCF',
	async ({ route, filters }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`api/${route}`, {
				params: {
					...filters
				}
			})

			return res.data
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getSIlist = createAsyncThunk('sales-invoice/printedInvoices/get',
	async ({ route, page, totalPage, orderBy, filters }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}`, {
				params: {
					page,
					totalPage,
					orderBy,
					...filters
				}
			})

			return res.data
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const downloadTemp = createAsyncThunk('data-management/downloadUploadtemplate/post',
	async ({ route, data }, { rejectWithValue }) => {
		// console.log(`${baseURL}/${route}`);
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).post(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success(`Download successfully!`)
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const directPrinting = createAsyncThunk('data-management/downloadUploadtemplate/post',
	async ({ route, data }, { rejectWithValue }) => {
		// console.log(`${baseURL}/${route}`);
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).post(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success(`Print successfully!`)
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

export {
	getReportCodes,
	getReport,
	getPricelist,
	postUpload,
	postPricelist,
	getPricelistDetails,
	patchPricelist,
	getSOitemlist,
	getSOlist,
	getSOitemHeader,
	patchRevLeak,
	userTriggerSOCF,
	getSIlist,
	downloadTemp,
	directPrinting
}
