import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../helpers/api';
import { toast } from 'react-toastify';

const baseURL = '/hls';
const headers = {
	'Content-Type': 'application/json',
}

const getReportCodes = createAsyncThunk('hls-report-sourceCode/get',
	async ({ route, report_id }, { rejectWithValue }) => {
		try {

			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}/report-sourcecode`, {
				params: {
					report_id
				}
			})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const getReport = createAsyncThunk('hls-report/post',
	async ({ route, data, downloadType }, { rejectWithValue }) => {
		try {
			let res;
			if (data.report === null || typeof data.report.value !== 'string') {
				toast.error('No report selected!')
			}
			else {
				res = await API({
					requestHeaders: {
						...headers
					}
				}).post(`${baseURL}/${route}/${downloadType}`, {
					data
				})
			}

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const postUpload = createAsyncThunk('hls-converter/post',
	async ({ route, data }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).post(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success('Successfully Uploaded!')
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

export {
	getReportCodes,
	getReport,
	postUpload
}