import { createSlice } from '@reduxjs/toolkit';
import {
	getReportCodes,
	getAutoSI,
	postAutoSI,
	patchAutoSI,
	getAutoSIdetails
} from './fms_autosi.thunk';

const initialState = {
	loading: false
}

const slice = createSlice({
	name: 'fms_autosi',
	initialState,
	reducers: {
		resetAction: () => initialState
	},
	extraReducers: {
		[getReportCodes.pending]: (state, action) => {
			state.loading = true
		},
		[getReportCodes.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getReportCodes.rejected]: (state, action) => {
			state.loading = false
		},
		[getAutoSI.pending]: (state, action) => {
			state.loading = true
		},
		[getAutoSI.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getAutoSI.rejected]: (state, action) => {
			state.loading = false
		},
		[postAutoSI.pending]: (state, action) => {
			state.loading = true
		},
		[postAutoSI.fulfilled]: (state, action) => {
			state.loading = false
		},
		[postAutoSI.rejected]: (state, action) => {
			state.loading = false
		},
		[patchAutoSI.pending]: (state, action) => {
			state.loading = true
		},
		[patchAutoSI.fulfilled]: (state, action) => {
			state.loading = false
		},
		[patchAutoSI.rejected]: (state, action) => {
			state.loading = false
		},
		[getAutoSIdetails.pending]: (state, action) => {
			state.loading = true
		},
		[getAutoSIdetails.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getAutoSIdetails.rejected]: (state, action) => {
			state.loading = false
		},
	}
})

export {
	getReportCodes,
	getAutoSI,
	postAutoSI,
	patchAutoSI,
	getAutoSIdetails
}
export default slice.reducer