import React from 'react';
import {
	Grid,
	Card,
	CardContent,
	Button,
	Typography,
	CardMedia,
	Box
} from '@mui/material';
import { useNavigate } from "react-router-dom";

import { modules } from '../../helpers';
import { ASCIIuserDialog } from '../../components/dialogs'

function Default(props) {
	const [openModalascii, setModalascii] = React.useState(false);
	const [dialogASCII, setDialogASCII] = React.useState(false);
	const [triggerASCII, setTriggerASCII] = React.useState(false);

	const navigate = useNavigate();

	const toggleChangeASCIIuser = () => {
		setModalascii(!openModalascii);
		setDialogASCII(!dialogASCII)

		if (dialogASCII === true) {
			setTriggerASCII(!triggerASCII)
		}
	}

	return (
		<Grid container>
			{
				modules.map((item, index) => {
					return (
						<Grid key={index} item container xs={12}>
							<Grid item xs={12}>
								<Typography variant='button'>{item.label.toUpperCase()}</Typography>
							</Grid>
							{
								item.subModules.map((sub, index,) => (
									<Cards key={index} label={sub.label} description={sub.label} path={sub.route} navigate={navigate} imgscr={sub.imgscr} />
								))
							}
						</Grid>
					)
				})
			}
			<Grid style={{ padding: '10px' }} sx={{ display: 'flex' }}>
				<Card sx={{ display: 'flex' }}>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<CardContent sx={{ flex: '1 0 auto' }}>
							<Typography component="div" variant="h5">
								Change ASCII API User
							</Typography>
							<Typography variant="subtitle1" color="text.secondary" component="div">
							Change ASCII API User
							</Typography>
						</CardContent>
						<Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
							<Button variant='kerry' onClick={toggleChangeASCIIuser} >OPEN</Button>
						</Box>
					</Box>

					<CardMedia
						component="img"
						sx={{ width: 100, display: 'top' }}
						style={{
							padding: '5px',
							margin: 'auto'
						}}
					/>
				</Card>
			</Grid>
			<ASCIIuserDialog isOpen={dialogASCII} toggle={() => { toggleChangeASCIIuser() }} />
		</Grid>
		
	);
}

const Cards = ({
	label,
	description,
	path,
	navigate,
	imgsrc
}) => {
	return (
		<Grid style={{ padding: '10px' }} sx={{ display: 'flex' }}>

			<Card sx={{ display: 'flex' }}>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<CardContent sx={{ flex: '1 0 auto' }}>
						<Typography component="div" variant="h5">
							{label}
						</Typography>
						<Typography variant="subtitle1" color="text.secondary" component="div">
							{description}
						</Typography>
					</CardContent>
					<Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
						<Button variant='kerry' onClick={() => navigate(path)}>VISIT</Button>
					</Box>
				</Box>

				<CardMedia
					component="img"
					sx={{ width: 100, display: 'top' }}
					style={{
						padding: '5px',
						margin: 'auto'
					}}
					image={imgsrc}
				/>
			</Card>
		</Grid>
	)
}

export default Default;