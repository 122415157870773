import { createSlice } from '@reduxjs/toolkit';
import { getReportCodes, getReport, postUpload, downloadTemp, getLocMapping, getLocMappingDetails, patchLocMapping, postLocMapping } from './wms_loc_mapping.thunk';

const initialState = {
	loading: false
}

const slice = createSlice({
	name: 'wms_loc_mapping.',
	initialState,
	reducers: {
		resetAction: () => initialState
	},
	extraReducers: {
		[getReportCodes.pending]: (state, action) => {
			state.loading = true
		},
		[getReportCodes.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getReportCodes.rejected]: (state, action) => {
			state.loading = false
		},
		[getReport.pending]: (state, action) => {
			state.loading = true
		},
		[getReport.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getReport.rejected]: (state, action) => {
			state.loading = false
		},
		[postUpload.pending]: (state, action) => {
			state.loading = true
		},
		[postUpload.fulfilled]: (state, action) => {
			state.loading = false
		},
		[postUpload.rejected]: (state, action) => {
			state.loading = false
		},
		[downloadTemp.pending]: (state, action) => {
			state.loading = true
		},
		[downloadTemp.fulfilled]: (state, action) => {
			state.loading = false
		},
		[downloadTemp.rejected]: (state, action) => {
			state.loading = false
		},
		[getLocMapping.pending]: (state, action) => {
			state.loading = true
		},
		[getLocMapping.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getLocMapping.rejected]: (state, action) => {
			state.loading = false
		},
		[getLocMappingDetails.pending]: (state, action) => {
			state.loading = true
		},
		[getLocMappingDetails.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getLocMappingDetails.rejected]: (state, action) => {
			state.loading = false
		},
		[patchLocMapping.pending]: (state, action) => {
			state.loading = true
		},
		[patchLocMapping.fulfilled]: (state, action) => {
			state.loading = false
		},
		[patchLocMapping.rejected]: (state, action) => {
			state.loading = false
		},
		[postLocMapping.pending]: (state, action) => {
			state.loading = true
		},
		[postLocMapping.fulfilled]: (state, action) => {
			state.loading = false
		},
		[postLocMapping.rejected]: (state, action) => {
			state.loading = false
		},
	}
})

export {
	getReportCodes,
	getReport,
	postUpload,
	downloadTemp,
	getLocMapping,
	getLocMappingDetails,
	patchLocMapping,
	postLocMapping
}
export default slice.reducer