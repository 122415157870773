import { createSlice } from '@reduxjs/toolkit';
import {
	getReportCodes,
	getReport,
	getUnsentList,
	postUnsent
} from './fms_unsent.thunk';

const initialState = {
	loading: false
}

const slice = createSlice({
	name: 'fms_unsent',
	initialState,
	reducers: {
		resetAction: () => initialState
	},
	extraReducers: {
		[getReport.pending]: (state, action) => {
			state.loading = true
		},
		[getReport.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getReport.rejected]: (state, action) => {
			state.loading = false
		},
		[getUnsentList.pending]: (state, action) => {
			state.loading = true
		},
		[getUnsentList.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getUnsentList.rejected]: (state, action) => {
			state.loading = false
		},
		[postUnsent.pending]: (state, action) => {
			state.loading = true
		},
		[postUnsent.fulfilled]: (state, action) => {
			state.loading = false
		},
		[postUnsent.rejected]: (state, action) => {
			state.loading = false
		},
	}
})

export {
	postUnsent,
	getReportCodes,
	getReport,
	getUnsentList,
}
export default slice.reducer