import { User } from '../../views/administration-user';
import { Role, RoleUpdate } from '../../views/administration-role';
import { Module } from '../../views/administration-module';
import { Report } from '../../views/administration-report';
import { QuickCode } from '../../views/administration-quickcode';

import {
	KairosReporthub,
	KairosConverter,
	LocMapping,
	StoreMapping
} from '../../views/wms';
import {
	NykeDataSync,
	NykeConverter,
	NykeConvertedViewer,
	NykeReporthub
} from '../../views/tms';
import { PlutusReporthub } from '../../views/fms';
import {
	SalemSalesInvoice,
	SalemItemPrice,
	SalemSalesOrder,
	SalemPrintedInvoice,
	SalemAutoSI,
	SalemUnsent
} from '../../views/slm';

const routes = [
	{
		name: 'users',
		label: 'Users',
		route: '/administration/user',
		module: 'Administration',
		subModule: 'Users',
		component: User
	},
	{
		name: 'roles',
		label: 'Roles',
		route: '/administration/role',
		module: 'Administration',
		subModule: 'Roles',
		component: Role
	},
	{
		name: 'roles_update',
		label: 'Roles Update',
		route: '/administration/role/update',
		module: 'Administration',
		subModule: 'Roles',
		component: RoleUpdate
	},
	{
		name: 'modules',
		label: 'Modules',
		route: '/administration/module',
		module: 'Administration',
		subModule: 'Modules',
		component: Module
	},
	{
		name: 'reports',
		label: 'Reports',
		route: '/administration/report',
		module: 'Administration',
		subModule: 'Reports',
		component: Report
	},
	{
		name: 'quickcode',
		label: 'Quick Code',
		route: '/administration/quickcode',
		module: 'Administration',
		subModule: 'QuickCodes',
		component: QuickCode
	},
	{
		name: 'kairos report hub',
		label: 'Kairos Report Hub',
		route: '/wms/reporthub',
		module: 'Warehouse Management',
		subModule: 'kairos report hub',
		component: KairosReporthub
	},
	{
		name: 'bin loc mapping',
		label: 'Bin Location Mapping',
		route: '/wms/locmapping',
		module: 'Warehouse Management',
		subModule: 'bin loc mapping',
		component: LocMapping
	},
	{
		name: 'kairos converter hub',
		label: 'Kairos Converter Hub',
		route: '/wms/converter',
		module: 'Warehouse Management',
		subModule: 'kairos converter hub',
		component: KairosConverter
	},
	
	{
		name: 'stc store mapping',
		route: '/wms/storemap',
		label: 'STC Store Mapping',
		module: 'Warehouse Management',
		subModule: 'stc store mapping',
		component: StoreMapping
	},
	{
		name: 'nyke report hub',
		label: 'Nyke Report Hub',
		route: '/tms/reporthub',
		module: 'Transport Management',
		subModule: 'nyke report hub',
		component: NykeReporthub
	},
	{
		name: 'nyke data sync',
		label: 'Nyke Data Sync',
		route: '/tms/datasync',
		module: 'Transport Management',
		subModule: 'nyke data sync',
		component: NykeDataSync
	},
	{
		name: 'nyke converter',
		label: 'Nyke Converter',
		route: '/tms/converter',
		module: 'Transport Management',
		subModule: 'nyke converter',
		component: NykeConverter
	},
	{
		name: 'nyke converter viewer',
		label: 'Nyke Converter Viewer',
		route: '/tms/converterviewer',
		module: 'Transport Management',
		subModule: 'nyke converter viewer',
		component: NykeConvertedViewer
	},
	{
		name: 'plutus report hub',
		label: 'Plutus Report Hub',
		route: '/fms/reporthub',
		module: 'Finance Management',
		subModule: 'plutus report hub',
		component: PlutusReporthub
	},
	{
		name: 'Sales Invoice',
		label: 'Salem Invoice Management',
		route: '/slm/salem',
		module: 'Finance Management',
		subModule: 'salem invoice management',
		component: SalemSalesInvoice
	},
	{
		name: 'SALEM Item Pricelist',
		label: 'SALEM Item Pricelist',
		route: '/slm/pricelist',
		module: 'Finance Management',
		subModule: 'salem item pricelist',
		component: SalemItemPrice
	},
	{
		name: 'Sales Order',
		label: 'Salem Order Item List',
		route: '/slm/salem/:id',
		module: 'Finance Management',
		subModule: 'salem invoice management',
		component: SalemSalesOrder
	},
	{
		name: 'Printed Sales Invoice',
		label: 'Printed Sales Invoice',
		route: '/slm/printed',
		module: 'Finance Management',
		subModule: 'salem invoice management',
		component: SalemPrintedInvoice
	},
	{
		name: 'Automated Sales Invoice Range',
		label: 'Automated Sales Invoice Range',
		route: '/slm/auto',
		module: 'Finance Management',
		subModule: 'salem invoice management',
		component: SalemAutoSI
	},
	{
		name: 'Unsent Sales Invoice in ASCII',
		label: 'Unsent Sales Invoice in ASCII',
		route: '/slm/unsent',
		module: 'Finance Management',
		subModule: 'salem invoice management',
		component: SalemUnsent
	}
]

export default routes;